import React from "react";
import { withRouter } from "react-router-dom";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import AppLayout from "../../layout/AppLayout";

import HotelList from "./hotels/index";
import HotelDetail from "./hotels/hotelDetail/index";
import ViewError from "../error";
import ViewUnauthorized from "../unauthorized";
import EditMenu from "./hotels/editMenu";
import AddMenu from "./hotels/addMenu";
import EditHotel from "./hotels/editHotel";
import AddHotel from "./hotels/addHotel";
import Accounts from "./accounts";
import OrderDetails from "./accounts/orderDetails";
import Cities from "./cities";
import Users from "./users";
import UserDetails from "./users/userDetails";

// const HotelList = React.lazy(() => import("./hotels/index"));
// const HotelDetail = React.lazy(() => import("./hotels/hotelDetail/index"));
// const ViewError = React.lazy(() => import("../error"));
// const ViewUnauthorized = React.lazy(() => import("../unauthorized"));
// const EditMenu = React.lazy(() => import("./hotels/editMenu"));
// const AddMenu = React.lazy(() => import("./hotels/addMenu"));
// const EditHotel = React.lazy(() => import("./hotels/editHotel"));
// const AddHotel = React.lazy(() => import("./hotels/addHotel"));
// const Accounts = React.lazy(() => import("./accounts"));
// const OrderDetails = React.lazy(() => import("./accounts/orderDetails"));
// const Cities = React.lazy(() => import("./cities"));
// const Users = React.lazy(() => import("./users"));
// const UserDetails = React.lazy(() => import("./users/userDetails"));

const App = () => {
  let match = useRouteMatch();

  return (
    <AppLayout>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/hotels`} />
        <Route
          path={`${match.url}/hotels`}
          render={(props) => <HotelList {...props} />}
        />
        <Route
          path={`${match.url}/accounts`}
          render={(props) => <Accounts {...props} />}
        />
        <Route
          path={`${match.url}/orderdetails`}
          render={(props) => <OrderDetails {...props} />}
        />
        <Route
          path={`${match.url}/hoteldetail`}
          render={(props) => <HotelDetail {...props} />}
        />
        <Route
          path={`${match.url}/add-hotel`}
          render={(props) => <AddHotel {...props} />}
        />
        <Route
          path={`${match.url}/edit-hotel`}
          render={(props) => <EditHotel {...props} />}
        />
        <Route
          path={`${match.url}/addmenu`}
          render={(props) => <AddMenu {...props} />}
        />
        <Route
          path={`${match.url}/editmenu`}
          render={(props) => <EditMenu {...props} />}
        />
        <Route
          path={`${match.url}/cities`}
          render={(props) => <Cities {...props} />}
        />
        <Route
          path={`${match.url}/users`}
          render={(props) => <Users {...props} />}
        />
        <Route
          path={`${match.url}/user-detail`}
          render={(props) => <UserDetails {...props} />}
        />
        <Route
          path="/error"
          exact
          render={(props) => <ViewError {...props} />}
        />
        <Route
          path="/unauthorized"
          exact
          render={(props) => <ViewUnauthorized {...props} />}
        />
        <Redirect to="/error" />
      </Switch>
    </AppLayout>
  );
};

export default withRouter(App);
