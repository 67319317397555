import React from "react";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { imagePlaceHolders } from "../../../../constants";
import { s3BucketConstants } from "../../../../constants";
import { LazyLoadImage } from "react-lazy-load-image-component";

const HotelDetails = ({ data, adminData }) => {
  const history = useHistory();

  // console.log("DATA", data);

  const onEditHotel = (id) => {
    history.push({
      pathname: "edit-hotel",
      state: { hotelId: id },
    });
  };

  return (
    <div className="white-card p-3 hotel-detail-card">
      <h4 className="card-title">Hotel Details</h4>
      <div>
        <button
          className="btn-blank hotel-edit f-semibold"
          onClick={() => onEditHotel(data.id)}
        >
          Edit
        </button>
      </div>
      <div className="pt-3">
        <Row xs="auto">
          <Col className="mb-3">
            <LazyLoadImage
              className="hotel-image"
              width="100%"
              height="100%"
              alt={"hotelImage"}
              effect="opacity"
              placeholderSrc={
                data.image
                  ? `${s3BucketConstants.IMAGE_BASE_URL}/${data.image}?tr=w-50,h-50,bl-10`
                  : imagePlaceHolders.HOTEL_IMAGE_PLACEHOLDER_150
              }
              src={
                data.image
                  ? `${s3BucketConstants.IMAGE_BASE_URL}/${data.image}?tr=w-300,h-300`
                  : imagePlaceHolders.HOTEL_IMAGE_PLACEHOLDER_150
              }
            />
          </Col>
          <Col>
            <h3 className="f-medium">{data.name}</h3>
            <div className="f-regular">{data.code}</div>
            <div className="f-regular">
              {data.addressLine1} {data.city} {data.province}
            </div>
            <div className="f-regular">{data.postalcode}</div>
            <div className="f-regular">{data.phone}</div>
            <div className="mt-3">
              <div className="f-regular">
                <span className="f-bold">Admin Email :</span> {adminData.email}
              </div>
              <div className="f-regular">
                <span className="f-bold">Admin Password :</span>{" "}
                {adminData.password}
              </div>
              <div className="f-regular">
                <span className="f-bold">Hotel Secret Code :</span>{" "}
                {adminData.secretCode}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HotelDetails;
