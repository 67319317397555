import React from "react";
import { Row } from "react-bootstrap";
import ConfirmDialog from "../../../../components/confirmDialog";
import MenuCard from "../../../../components/menuCard";

const HotelMenu = ({
  menuData,
  openDialog,
  onClose,
  onProceed,
  onAddMenu,
  onEditMenu,
  showDialog,
}) => {
  return (
    <div className="white-card p-3 my-2 menu-list-card">
      <h4 className="card-title">Menu</h4>
      <div>
        <button className="btn-blank hotel-edit f-semibold" onClick={onAddMenu}>
          Add
        </button>
      </div>
      <div className="py-3">
        <Row>
          {menuData.map((item) => (
            <MenuCard
              key={item.id}
              title={item.name}
              image={item.image}
              description={item.description}
              price={item.price}
              onEdit={() => onEditMenu(item.id)}
              onDelete={() => showDialog(item.id)}
            />
          ))}
        </Row>
      </div>
      <ConfirmDialog
        title="Confirm Delete"
        description="Are you sure you want to delete?"
        closeButtonText="NO"
        actionButtonText="YES"
        {...{ openDialog, onClose, onProceed }}
      />
    </div>
  );
};

export default HotelMenu;
