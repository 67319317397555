import React from "react";
import { Col, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { imagePlaceHolders, s3BucketConstants } from "../../../../constants";

const OrderedItemRow = ({ item }) => {
  const totalItemPrice = +item.price * +item.quantity;

  // console.log("ITEMMM", item);

  return (
    <Row className="my-3">
      <Col xs={4} md={2} lg={2}>
        <div>
          {/* <img
            src={
              item.image
                ? `${s3BucketConstants.IMAGE_BASE_URL}/${item.image}`
                : imagePlaceHolders.MENU_IMAGE_PLACEHOLDER_75
            }
            className="order-item-image"
            alt="ordereditem"
          /> */}
          <LazyLoadImage
            className="order-item-image"
            width="100%"
            height="100%"
            alt={"ordereditem"}
            effect="opacity"
            placeholderSrc={
              item.image
                ? `${s3BucketConstants.IMAGE_BASE_URL}/${item.image}?tr=w-10,h-10,bl-10`
                : imagePlaceHolders.MENU_IMAGE_PLACEHOLDER_75
            }
            src={
              item.image
                ? `${s3BucketConstants.IMAGE_BASE_URL}/${item.image}?tr=w-150,h-150`
                : imagePlaceHolders.MENU_IMAGE_PLACEHOLDER_75
            }
          />
        </div>
      </Col>
      <Col xs={8} md={10} lg={10}>
        <Row>
          <Col xs={12} md={8} lg={8} style={{ alignSelf: "center" }}>
            <div>
              <div className="f-medium ordered-item-name">
                {item.productName}
              </div>
              {/* <div className="f-regular ordered-item-desc">
                check with server
              </div> */}
            </div>
          </Col>
          <Col xs={12} md="auto" style={{ alignSelf: "center" }}>
            <div className="f-medium">
              ${item.price} x {item.quantity}
            </div>
          </Col>
          <Col
            xs={12}
            md="auto"
            // className="px-5"
            style={{ alignSelf: "center" }}
          >
            <div className="f-bold">${Number(totalItemPrice).toFixed(2)}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default OrderedItemRow;
