import React from "react";
import { Col, Row } from "react-bootstrap";
import { Button, TextField } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import Loader from "../loader";
import { s3BucketConstants } from "../../constants";

const AddMenuCard = ({
  editMode = false,
  loading,
  selectedFile,
  preview,
  hotelID,
  name,
  description,
  price,
  image,
  formError,
  onChangeName,
  onChangeDescription,
  onChangePrice,
  onSelectFile,
  onSubmit,
}) => {
  const history = useHistory();
  const goBack = () =>
    history.push({
      pathname: "hoteldetail",
      state: { hotelId: hotelID },
    });

  return (
    <div className="px-4 py-4">
      <div className="white-card p-3 my-2">
        {loading ? (
          <Loader />
        ) : (
          <>
            <h4 className="card-title">{editMode ? "Edit" : "Add"} Menu</h4>
            <div className="py-3 px-3">
              <Row>
                <Col lg={5} className="mb-3">
                  {editMode ? (
                    <>
                      {selectedFile || image ? (
                        <img
                          src={
                            selectedFile
                              ? preview
                              : `${s3BucketConstants.IMAGE_BASE_URL}/${image}`
                          }
                          className="edit-menu-image"
                          alt="hotelImage"
                        />
                      ) : (
                        <div className="edit-menu-image image-placeholder f-regular">
                          No Image
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {selectedFile ? (
                        <img
                          src={preview}
                          className="edit-hotel-image"
                          alt="hotel"
                        />
                      ) : (
                        <div className="edit-menu-image image-placeholder f-regular">
                          No Image
                        </div>
                      )}
                    </>
                  )}
                  <div className="text-center">
                    <div className="upload-btn-wrapper upload-btn">
                      <button className="btn-blank f-semibold">
                        SELECT IMAGE
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        onChange={onSelectFile}
                      />
                    </div>
                  </div>
                  {formError.image && (
                    <div className="error-text mt-1">{formError.image}</div>
                  )}
                </Col>

                <Col lg={7} className="edit-hotel-form">
                  <div className="mb-4">
                    <TextField
                      id="standard-basic"
                      label="Item name"
                      variant="standard"
                      fullWidth
                      value={name}
                      onChange={onChangeName}
                    />
                    {formError.name && (
                      <div className="error-text mt-1">{formError.name}</div>
                    )}
                  </div>

                  <div className="mb-4">
                    <TextField
                      id="standard-basic"
                      label="Description"
                      variant="standard"
                      fullWidth
                      value={description}
                      onChange={onChangeDescription}
                    />
                    {formError.description && (
                      <div className="error-text mt-1">
                        {formError.description}
                      </div>
                    )}
                  </div>

                  <div className="mb-4">
                    <TextField
                      id="standard-basic"
                      label="Price"
                      variant="standard"
                      type="number"
                      value={price}
                      onChange={onChangePrice}
                    />
                    {formError.price && (
                      <div className="error-text mt-1">{formError.price}</div>
                    )}
                  </div>
                  <div className="text-end">
                    <Button
                      variant="contained"
                      className="save-btn f-semibold"
                      onClick={onSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
            <button className="btn-blank close-icon-container" onClick={goBack}>
              <AiOutlineClose size={20} />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default AddMenuCard;
