import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function CategoryDropDown({ value, onSelectItem = () => {} }) {
  const [category, setCategory] = React.useState("BWRY");

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  React.useEffect(() => {
    onSelectItem(category);
  }, [onSelectItem, category]);

  React.useEffect(() => {
    setCategory(value);
  }, [value]);

  return (
    <div>
      <FormControl variant="standard" sx={{ m: 0, minWidth: 220 }}>
        <InputLabel id="demo-simple-select-standard-label">Category</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={category}
          onChange={handleChange}
          label="Category"
        >
          <MenuItem value="BWRY">Brewery</MenuItem>
          <MenuItem value="PUB">Pub</MenuItem>
          <MenuItem value="RSNT">Restaurant</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
