import React from "react";
import { Col, Row } from "react-bootstrap";

const ShopDetailCard = ({ orderData }) => {
  return (
    <div className="white-card px-3 py-3 my-3">
      <h5 className="card-title">Shop Details</h5>
      <div className="horizontal-line" />
      <Row xs="auto" className="pb-3">
        <Col xs={2} className="app-text shop-detail-title">
          Shop Name
        </Col>
        <Col className="app-text">{orderData.storeName}</Col>
      </Row>
      <Row xs="auto" className="pb-3">
        <Col xs={2} className="app-text shop-detail-title">
          Address
        </Col>
        <Col className="app-text">{orderData.storeAddressLine1}</Col>
      </Row>
      <Row xs="auto">
        <Col xs={2} className="app-text shop-detail-title">
          Phone
        </Col>
        <Col className="app-text">{orderData.storePhone}</Col>
      </Row>
    </div>
  );
};

export default ShopDetailCard;
