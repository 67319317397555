import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { s3BucketConstants } from "../../constants";
import CategoryDropDown from "../dropdown/categoryDropDown.js";
import CityDropDown from "../dropdown/cityDropDown";
import Loader from "../loader";

// const cityData = [
//   {
//     id: "001",
//     name: "Toronto",
//   },
//   {
//     id: "002",
//     name: "Vancouver",
//   },
//   {
//     id: "003",
//     name: "Ottawa",
//   },
//   {
//     id: "004",
//     name: "Hamilton",
//   },
// ];

const EditHotelCard = ({
  editMode = false,
  id,
  formError,
  selectedFile,
  preview,
  hotelName,
  address,
  hotelId,
  postalCode,
  loading,
  province,
  phoneNumber,
  category,
  adminEmail,
  adminPassword,
  secretCode,
  city,
  cityData,
  image,
  onChangeAdminEmail,
  onChangeAdminPassword,
  onChangeSecretCode,
  onSelectFile,
  onChangeHotelName,
  onChangeAddress,
  onChangeProvince,
  onChangePhoneNumber,
  onChangeHotelId,
  onChangePostalCode,
  onChangeCategory,
  onSelectCity,
  onSubmit,
}) => {
  const history = useHistory();
  const goBack = () => history.goBack();
  const [showCityPicker, setShowCityPicker] = useState(editMode ? false : true);

  const gotToHotelPage = () =>
    history.push({
      pathname: "hoteldetail",
      state: { hotelId: id },
    });

  return (
    <div className="px-4 py-4">
      <div className="white-card p-3 my-2">
        {loading ? (
          <Loader />
        ) : (
          <>
            <h4 className="card-title">{editMode ? "Edit" : "Add"} Hotel</h4>
            <div className="py-3 px-3">
              <Row>
                <Col md={5} className="add-hotel-image-col">
                  {editMode ? (
                    <>
                      {selectedFile || image ? (
                        // <img
                        //   className="edit-hotel-image"
                        //   src={
                        //     selectedFile
                        //       ? preview
                        //       : `${s3BucketConstants.IMAGE_BASE_URL}/${image}`
                        //   }
                        //   alt="Hotel"
                        // />
                        <LazyLoadImage
                          className="edit-hotel-image"
                          width="100%"
                          height="400px"
                          alt={"Hotel"}
                          effect="opacity"
                          placeholderSrc={
                            selectedFile
                              ? preview
                              : `${s3BucketConstants.IMAGE_BASE_URL}/${image}?tr=w-20,h-20,bl-10`
                          }
                          src={
                            selectedFile
                              ? preview
                              : `${s3BucketConstants.IMAGE_BASE_URL}/${image}?tr=w-300,h-300`
                          }
                        />
                      ) : (
                        <div className="edit-menu-image image-placeholder f-regular">
                          No Image
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {selectedFile ? (
                        <img
                          src={preview}
                          className="edit-hotel-image"
                          alt="Hotel"
                        />
                      ) : (
                        <div className="edit-menu-image image-placeholder f-regular">
                          No Image
                        </div>
                      )}
                    </>
                  )}
                  <div className="text-center">
                    <div className="upload-btn-wrapper upload-btn">
                      <button className="btn-blank f-semibold">
                        SELECT IMAGE
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        onChange={onSelectFile}
                      />
                    </div>
                  </div>
                  {formError.image && (
                    <div className="error-text mt-1">{formError.image}</div>
                  )}
                </Col>
                <Col md={7} className="edit-hotel-form">
                  <div className="mb-4">
                    <TextField
                      id="standard-basic"
                      label="Hotel name"
                      variant="standard"
                      fullWidth
                      value={hotelName}
                      onChange={onChangeHotelName}
                    />
                    {formError.hotelName && (
                      <div className="error-text mt-1">
                        {formError.hotelName}
                      </div>
                    )}
                  </div>
                  <div className="mb-4">
                    <TextField
                      id="standard-basic"
                      label="Address"
                      variant="standard"
                      fullWidth
                      value={address}
                      onChange={onChangeAddress}
                    />
                    {formError.address && (
                      <div className="error-text mt-1">{formError.address}</div>
                    )}
                  </div>
                  <div className="mb-4">
                    <TextField
                      id="standard-basic"
                      label="Hotel ID"
                      variant="standard"
                      fullWidth
                      value={hotelId}
                      onChange={onChangeHotelId}
                    />
                    {formError.hotelId && (
                      <div className="error-text mt-1">{formError.hotelId}</div>
                    )}
                  </div>
                  <div className="mb-4">
                    <TextField
                      id="standard-basic"
                      label="Phone"
                      variant="standard"
                      fullWidth
                      value={phoneNumber}
                      onChange={onChangePhoneNumber}
                    />
                    {formError.phoneNumber && (
                      <div className="error-text mt-1">
                        {formError.phoneNumber}
                      </div>
                    )}
                  </div>
                  <div className="mb-4">
                    {!showCityPicker ? (
                      <div>
                        <div>City</div>
                        <div className="d-row align-center mt-2">
                          <div className="f-semibold">{city.name}</div>
                          <button
                            className="btn-blank blue-btn f-regular mx-4"
                            onClick={() => setShowCityPicker(true)}
                          >
                            Change city
                          </button>
                        </div>
                      </div>
                    ) : (
                      <CityDropDown
                        data={cityData}
                        onSelectItem={onSelectCity}
                      />
                    )}

                    {formError.city && (
                      <div className="error-text mt-1">{formError.city}</div>
                    )}
                  </div>
                  <div className="mb-4">
                    <TextField
                      id="standard-basic"
                      label="Province"
                      variant="standard"
                      fullWidth
                      value={province}
                      onChange={onChangeProvince}
                    />
                    {formError.province && (
                      <div className="error-text mt-1">
                        {formError.province}
                      </div>
                    )}
                  </div>
                  <div className="mb-4">
                    <TextField
                      id="standard-basic"
                      label="Postal code"
                      variant="standard"
                      fullWidth
                      value={postalCode}
                      onChange={onChangePostalCode}
                    />
                    {formError.postalCode && (
                      <div className="error-text mt-1">
                        {formError.postalCode}
                      </div>
                    )}
                  </div>

                  <div className="mb-4">
                    <TextField
                      id="standard-basic"
                      label="Admin email"
                      variant="standard"
                      fullWidth
                      value={adminEmail}
                      onChange={onChangeAdminEmail}
                    />
                    {formError.adminEmail && (
                      <div className="error-text mt-1">
                        {formError.adminEmail}
                      </div>
                    )}
                  </div>

                  <div className="mb-4">
                    <TextField
                      id="standard-basic"
                      label="Admin password"
                      variant="standard"
                      fullWidth
                      value={adminPassword}
                      onChange={onChangeAdminPassword}
                    />
                    {formError.adminPassword && (
                      <div className="error-text mt-1">
                        {formError.adminPassword}
                      </div>
                    )}
                  </div>
                  <div className="mb-4">
                    <TextField
                      id="standard-basic"
                      label="Hotel secret code"
                      variant="standard"
                      fullWidth
                      value={secretCode}
                      onChange={onChangeSecretCode}
                    />
                    {formError.secretCode && (
                      <div className="error-text mt-1">
                        {formError.secretCode}
                      </div>
                    )}
                  </div>

                  <div className="mb-4">
                    <CategoryDropDown
                      value={category}
                      onSelectItem={onChangeCategory}
                    />
                  </div>

                  <div className="text-end">
                    <Button
                      variant="contained"
                      className="save-btn f-semibold"
                      onClick={onSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
            <button
              className="btn-blank close-icon-container"
              onClick={editMode ? gotToHotelPage : goBack}
            >
              <AiOutlineClose size={20} />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default EditHotelCard;
