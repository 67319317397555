import React from "react";
import { Col, Row } from "react-bootstrap";

const PriceDetailCard = ({ orderData }) => {
  return (
    <div className="white-card px-3 py-3 my-3">
      <h5 className="card-title">Price Details</h5>
      <div className="horizontal-line" />
      <Row xs="auto">
        <Col className="app-text f-semibold" xs={2}>
          Price
        </Col>
        <Col className="app-text">
          $ {Number(orderData.subTotalPrice).toFixed(2)}
        </Col>
      </Row>
      <Row xs="auto" className="mt-2">
        <Col className="app-text f-semibold" xs={2}>
          Tax
        </Col>
        <Col className="app-text">
          $ {Number(orderData.taxCharges).toFixed(2)}
        </Col>
      </Row>
      <Row xs="auto" className="mt-2">
        <Col className="app-text f-semibold" xs={2}>
          Service Fee
        </Col>
        <Col className="app-text">
          $ {Number(orderData.serviceCharges).toFixed(2)}
        </Col>
      </Row>
      <Row xs="auto" className="mt-2">
        <Col className="app-text f-semibold" xs={2}>
          Server Tip
        </Col>
        <Col className="app-text">
          $ {Number(orderData.tipAmount).toFixed(2)}
        </Col>
      </Row>
      <Row xs="auto" className="mt-2">
        <Col className="app-text f-bold" xs={2}>
          Total
        </Col>
        <Col className="app-text f-bold">
          $ {Number(orderData.totalPrice).toFixed(2)}
        </Col>
      </Row>
    </div>
  );
};

export default PriceDetailCard;
