import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import CityTable from "./cityTable";
import Loader from "../../../components/loader";
import AddCityModal from "./addCity";
import useCities from "./util";

const Cities = () => {
  const { cityData, page, pageCount, loading, getCityData, handlePageChange } =
    useCities();

  const [showAddForm, setShowAddForm] = useState(false);

  const showAddFormModal = () => setShowAddForm(true);
  const hideAddFormModal = () => setShowAddForm(false);

  return (
    <div className="page-container">
      {loading ? (
        <Loader />
      ) : (
        <>
          <h2 className="page-title">Cities</h2>
          <div className="text-end">
            <button
              className="btn-blank add-btn py-1 px-3"
              onClick={showAddFormModal}
            >
              Add City
            </button>
          </div>
          <CityTable
            refetchData={getCityData}
            {...{
              cityData,
            }}
          />
          <Pagination
            count={pageCount}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
          />
        </>
      )}
      <AddCityModal
        visible={showAddForm}
        hideAddFormModal={hideAddFormModal}
        refetchData={getCityData}
      />
    </div>
  );
};

export default Cities;
