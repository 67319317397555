import React from "react";
import Loader from "../../../../components/loader";
import HotelDetails from "./hotelDetails";
import HotelMenu from "./menu";
import useHotelDetail from "./util";

const HotelDetail = () => {
  const {
    hotelData,
    menuData,
    adminData,
    openDialog,
    onClose,
    onProceed,
    loading1,
    loading2,
    onAddMenu,
    onEditMenu,
    showDialog,
  } = useHotelDetail();
  return (
    <div className="px-4 py-4">
      {loading1 || loading2 ? (
        <Loader />
      ) : (
        <>
          <HotelDetails data={hotelData} adminData={adminData} />
          <HotelMenu
            {...{
              menuData,
              openDialog,
              onClose,
              onProceed,
              onAddMenu,
              onEditMenu,
              showDialog,
            }}
          />
        </>
      )}
    </div>
  );
};

export default HotelDetail;
