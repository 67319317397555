import { useEffect, useState } from "react";
import Compress from "react-image-file-resizer";

const useImageUploader = () => {
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    // setSelectedFile(e.target.files[0]);
    const file = e.target.files[0];
    // console.log("Original", file);
    Compress.imageFileResizer(
      file, // the file from input
      480, // width
      480, // height
      "JPEG", // compress format WEBP, JPEG, PNG
      70, // quality
      0, // rotation
      (uri) => {
        // console.log("Compressed", uri);
        setSelectedFile(uri);
        // You upload logic goes here
      },
      "file" // blob or base64 default base64
    );
  };
  return { selectedFile, preview, onSelectFile };
};

export default useImageUploader;
