import React from "react";
import { Table } from "react-bootstrap";
import ConfirmDialog from "../../../components/confirmDialog";

const Hoteltable = ({
  hotels,
  showConfirmation,
  goToDetails,
  openDialog,
  onClose,
  onProceed,
}) => {
  return (
    <div className="my-3">
      <Table responsive="sm">
        <thead>
          <tr>
            <th>Hotel Id</th>
            <th>Hotel Name</th>
            <th>Address</th>
            <th>Postal code</th>
            <th>Contact Number</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        {hotels.length > 0 && (
          <tbody>
            {hotels.map((item) => (
              <tr className="table-row" key={item.id}>
                <td className="py-3">{item.storeId}</td>
                <td className="py-3">{item.name}</td>
                <td className="py-3">{item.addressLine1}</td>
                <td className="py-3">{item.postalcode}</td>
                <td className="py-3">{item.phone}</td>
                <td className="py-3">
                  <button
                    className="btn-blank view-detail-btn"
                    onClick={() => goToDetails(item.id)}
                  >
                    View Details
                  </button>
                </td>
                <td className="py-3">
                  <button
                    className="btn-blank view-detail-btn px-2"
                    onClick={() => showConfirmation(item.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      <ConfirmDialog
        title="Confirm Delete"
        description="Are you sure you want to delete?"
        closeButtonText="NO"
        actionButtonText="YES"
        {...{ openDialog, onClose, onProceed }}
      />
    </div>
  );
};

export default Hoteltable;
