import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { addMenu } from "../../../../api";
import S3Bucket from "../../../../appConfig/aws-config";
import { s3BucketConstants } from "../../../../constants";
import useAlert from "../../../../hooks/alert-hook";
import useImageUploader from "../../../../hooks/image-uploader";
import {
  validateImage,
  validateName,
  validatePrice,
} from "../../../../validation/validator";

const useAddMenu = () => {
  const { selectedFile, preview, onSelectFile } = useImageUploader();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(true);
  const { showAppAlert } = useAlert();
  const [formError, setFormError] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const hotelID = location.state.hotelId;
  const storeID = location.state.storeId;

  const onChangeName = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const onChangeDescription = useCallback((e) => {
    setDescription(e.target.value);
  }, []);

  const onChangePrice = useCallback((e) => {
    setPrice(e.target.value);
  }, []);

  useEffect(() => {
    setLoading(false);
  }, []);

  const validator = {
    name: validateName(name),
    description: validateName(description),
    price: validatePrice(price),
    image: validateImage(selectedFile),
  };

  const checkFormError = () => {
    setFormError(validator);
  };

  const onAddMenu = (fileName = "") => {
    const body = {
      name,
      description,
      price,
      image: fileName,
    };
    // console.log("Add menu body", body);
    addMenu(hotelID, body)
      .then((res) => {
        if (res) {
          setLoading(false);
          showAppAlert("success", "Added successfully");
          history.push({
            pathname: "hoteldetail",
            state: { hotelId: hotelID },
          });
        }
      })
      .catch((err) => setLoading(false));
  };

  const uploadImage = () => {
    const uniqueId = `${storeID}-${Date.now()}`.replace(" ", "_");

    const fileName = `${
      s3BucketConstants.MENU_IMAGE_FOLDER
    }/${uniqueId}.${selectedFile.name.split(".").pop()}`;

    const params = {
      Body: selectedFile,
      Bucket: s3BucketConstants.BUCKET_NAME,
      Key: fileName,
      ACL: "public-read",
      ContentDisposition: "inline",
      ContentType: selectedFile.type,
    };

    try {
      S3Bucket.putObject(params)
        .on("httpUploadProgress", (evt) => {
          // console.log("Progess", Math.round((evt.loaded / evt.total) * 100));
        })
        .send((err) => {
          if (err) {
            // console.log("Image upload failed", err);
            showAppAlert("error", "Image upload failed");
            setLoading(false);
          } else {
            onAddMenu(fileName);
          }
        });
    } catch (error) {
      setLoading(false);
      // console.log("Upload error", error);
      showAppAlert("error", "Image upload failed");
    }
  };

  const onSubmit = () => {
    checkFormError();
    const invalidForm = Object.values(validator).some((x) => x !== false);
    if (!invalidForm) {
      setLoading(true);
      uploadImage();
    }
  };

  return {
    loading,
    selectedFile,
    preview,
    hotelID,
    name,
    description,
    price,
    formError,
    onChangeName,
    onChangeDescription,
    onChangePrice,
    onSelectFile,
    onSubmit,
  };
};

export default useAddMenu;
