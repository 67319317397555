import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  getHotelDetailsById,
  getMenuByHotelId,
  deleteMenu,
  getStoreAdminDetailsById,
} from "../../../../api";
import useAlert from "../../../../hooks/alert-hook";

const useHotelDetail = () => {
  const [hotelData, setHotelData] = useState({});
  const [adminData, setAdminData] = useState({});
  const [menuData, setMenuData] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const { showAppAlert } = useAlert();

  const history = useHistory();
  const location = useLocation();

  const hotelID = location?.state?.hotelId;

  // console.log("hotel id", hotelID);

  // console.log("hotelData", hotelData);

  // useEffect(() => {
  //   return () => {
  //     if (history.action === "POP") {
  //       history.push("hotels");
  //     }
  //   };
  // }, [history]);

  const onAddMenu = () => {
    history.push({
      pathname: "addmenu",
      state: { hotelId: hotelID, storeId: hotelData.storeId },
    });
  };

  const onEditMenu = (id) => {
    history.push({
      pathname: "editmenu",
      state: { hotelId: hotelID, menuId: id, storeId: hotelData.storeId },
    });
  };

  const getAdminDetails = () => {
    getStoreAdminDetailsById(hotelID).then((res) => {
      if (res) {
        if (!res.error) {
          setAdminData(res[0]);
        }
      }
    });
  };

  const fetchHotelMenu = () => {
    setLoading1(true);
    getMenuByHotelId(hotelID).then((res) => {
      if (res) {
        if (!res.error) {
          setLoading1(false);
          setMenuData(res);
        } else {
          // console.log("Get hotel menu error", res.message);
        }
      }
    });
  };

  useEffect(() => {
    getAdminDetails();
    fetchHotelMenu();
  }, [hotelID]);

  useEffect(() => {
    setLoading2(true);
    getHotelDetailsById(hotelID).then((res) => {
      if (res) {
        if (!res.error) {
          setLoading2(false);
          setHotelData(res);
        } else {
          // console.log("Hotel details error", res.message);
        }
      }
    });
  }, [hotelID]);

  const onDeleteMenu = (id) => {
    setLoading1(true);
    setOpenDialog(false);
    deleteMenu(id).then((res) => {
      if (res) {
        if (res.httpStatus === "OK") {
          setDeleteId("");
          showAppAlert("success", "Deleted successfully");
          fetchHotelMenu();
        } else if (res.error) {
          // console.log("Delete hotel menu", res.message);
        } else {
          // console.log("Something went wrong");
        }
      }
    });
  };

  const showDialog = (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };

  const onProceed = () => {
    onDeleteMenu(deleteId);
  };

  const onClose = () => setOpenDialog(false);

  return {
    hotelData,
    menuData,
    adminData,
    openDialog,
    onClose,
    onProceed,
    loading1,
    loading2,
    onAddMenu,
    onEditMenu,
    showDialog,
  };
};

export default useHotelDetail;
