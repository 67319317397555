import React, { useState, createContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { api } from "../api/Utils";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ErrorContext = createContext();

const ErrorHandlerProvider = (props) => {
  const [errorData, setErrorData] = useState({
    visible: false,
    mode: "",
    message: "",
  });
  const [offline, setOffline] = useState(false);

  const interceptorUrlBlackList = ["login"];

  const showAppAlert = (mode, message) => {
    setErrorData({
      visible: true,
      mode,
      message,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorData({
      visible: false,
      mode: "",
      message: "",
    });
  };

  // Add a request interceptor
  api.interceptors.request.use(
    function (config) {
      //   // console.log("Request Config", config);
      return config;
    },
    function (error) {
      // Do something with request error
      if (error.request) {
        setErrorData({
          visible: true,
          mode: "error",
          message: error.request,
        });
        // alert(error.request.toString());
      }
      // console.log("Request Error", error);
      return Promise.reject(error);
    }
  );

  // Response interceptor
  api.interceptors.response.use(
    function (response) {
      //   // console.log("Response config", response);
      return response;
    },
    function (error) {
      // console.log("Response Error", error);
      //   // console.log("Url", error?.response?.config?.url);
      const hideError = interceptorUrlBlackList.includes(
        error?.response?.config?.url
      );
      if (!hideError) {
        if (error.response) {
          //   alert(error.response.data.message);
          setErrorData({
            visible: true,
            mode: "error",
            message: error.response.data.message,
          });
        } else if (error.message) {
          if (error.message) {
            // alert(error.message);
            if (error.message === "Network Error") {
              // console.log("No Internet Available");
              setOffline(true);
            } else {
              setErrorData({
                visible: true,
                mode: "error",
                message: error.message,
              });
            }
          }
        } else {
          setErrorData({
            visible: true,
            mode: "error",
            message: error.toString(),
          });
          //   alert(error.toString());
        }
      }
      return Promise.reject(error);
    }
  );

  function refreshPage() {
    window.location.reload(false);
  }

  if (offline) {
    return (
      <div className="offline-container">
        <div className="offline-box">
          <div className="offline-heading">Slow or no internet connection.</div>
          <div className="offline-text">Please try again</div>
          <button className="btn btn-blank retry-btn" onClick={refreshPage}>
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <ErrorContext.Provider value={{ showAppAlert }}>
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={errorData.visible}
          autoHideDuration={3000}
          onClose={handleClose}
          style={{ marginTop: 25 }}
        >
          <Alert
            onClose={handleClose}
            severity={errorData.mode}
            sx={{ width: "100%" }}
          >
            {errorData.message}
          </Alert>
        </Snackbar>
        {props.children}
      </>
    </ErrorContext.Provider>
  );
};

export default ErrorHandlerProvider;
