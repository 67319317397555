const urls = {
  loginUrl: "login",
  addHotel: "addStore",
  getHotels: "getStores",
  getHotelDetailsById: "getStoreDetails",
  editHotel: "editStore",
  addMenu: "addMenu",
  getMenus: "getMenus",
  getMenuDetailsById: "getMenuDetails",
  editMenu: "editMenu",
  deleteHotel: "deleteStore",
  deleteMenu: "deleteMenu",
  getStoreAdminDetails: "getStoreAdminDetails",
  getOrders: "getOrders",
  getOrderDetails: "getOrderDetails",
  addCity: "addCity",
  editCity: "editCity",
  getCities: "getCities",
  findUsers: "findUsers",
  getUserDetailsById: "findUser",
};

export default urls;
