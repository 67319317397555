import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useAddCity from "./util";

export default function AddCityModal({
  visible,
  hideAddFormModal,
  refetchData,
}) {
  const { city, open, formError, onChangeCity, handleClose, handleSubmit } =
    useAddCity({
      visible,
      hideAddFormModal,
      refetchData,
    });

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add City</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
           Add a valid city name
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="normal"
            id="name"
            label="City"
            type="text"
            fullWidth
            variant="standard"
            value={city}
            onChange={onChangeCity}
          />
          {formError.city && (
            <div className="error-text mt-1">{formError.city}</div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>ADD</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
