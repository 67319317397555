import { useEffect, useState } from "react";
import { getCities } from "../../../api";

const useCities = () => {
  const [cityData, setCityData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const pageSize = 25;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getCityData = () => {
    setLoading(true);
    const body = {
      pageNo: page - 1,
      pageSize,
    };
    getCities(body)
      .then((res) => {
        setCityData(res.content);
        setPageCount(Math.ceil(res.totalElements / pageSize));
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    getCityData();
  }, [page]);

  return { cityData, page, pageCount, loading, getCityData, handlePageChange };
};

export default useCities;
