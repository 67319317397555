import React from "react";
import AddMenuCard from "../../../../components/addMenuCard";
import useAddMenu from "./util";

const AddMenu = () => {
  const {
    loading,
    selectedFile,
    preview,
    hotelID,
    name,
    description,
    price,
    formError,
    onChangeName,
    onChangeDescription,
    onChangePrice,
    onSelectFile,
    onSubmit,
  } = useAddMenu();
  return (
    <AddMenuCard
      {...{
        loading,
        selectedFile,
        preview,
        hotelID,
        name,
        description,
        price,
        formError,
        onChangeName,
        onChangeDescription,
        onChangePrice,
        onSelectFile,
        onSubmit,
      }}
    />
  );
};

export default AddMenu;
