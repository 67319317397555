import { useContext } from "react";
import { ErrorContext } from "../context/errorhandlerContext";

const useAlert = () => {
  const { showAppAlert } = useContext(ErrorContext);

  return { showAppAlert };
};

export default useAlert;
