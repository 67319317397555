import React from "react";
import { useHistory } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Hoteltable from "./hotelTable";
import useHotelList from "./util";
import Loader from "../../../components/loader";

const Hotels = () => {
  const history = useHistory();
  const {
    hotels,
    page,
    pageCount,
    loading,
    openDialog,
    onClose,
    onProceed,
    showConfirmation,
    handlePageChange,
  } = useHotelList();

  const goToDetails = (id) => {
    history.push({ pathname: "hoteldetail", state: { hotelId: id } });
  };

  const addHotel = () => {
    history.push("add-hotel");
  };

  return (
    <div className="page-container">
      {loading ? (
        <Loader />
      ) : (
        <>
          <h2 className="page-title">Hotels</h2>
          <div className="text-end">
            <button className="btn-blank add-btn py-1 px-3" onClick={addHotel}>
              Add Hotel
            </button>
          </div>
          <Hoteltable
            {...{
              hotels,
              showConfirmation,
              goToDetails,
              openDialog,
              onClose,
              onProceed,
            }}
          />
          <Pagination
            count={pageCount}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
};

export default Hotels;
