import React from "react";
import Pagination from "@mui/material/Pagination";
import Hoteltable from "./userTable";
import useHotelList from "./util";
import Loader from "../../../components/loader";

const Users = () => {
  const { users, page, pageCount, loading, handlePageChange } = useHotelList();

  // const goToDetails = (id) => {
  //   history.push({ pathname: "user-detail", state: { hotelId: id } });
  // };

  return (
    <div className="page-container">
      {loading ? (
        <Loader />
      ) : (
        <>
          <h2 className="page-title">Users</h2>
          <Hoteltable
            {...{
              users,
            }}
          />
          <Pagination
            count={pageCount}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
};

export default Users;
