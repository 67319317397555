import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDialog({
  title,
  description,
  closeButtonText,
  actionButtonText,
  openDialog,
  onClose,
  onProceed,
}) {
  return (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            backgroundColor: "#383838",
          },
        }}
      >
        <DialogTitle style={{ color: "#fff" }}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: "#A8C4C4" }}
          >
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ fontWeight: "bold" }} onClick={onClose}>
            {closeButtonText}
          </Button>
          <Button style={{ fontWeight: "bold" }} onClick={onProceed}>
            {actionButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
