import React from "react";
import EditHotelCard from "../../../../components/editHotelCard";
import useAddHotel from "./util";

const AddHotel = () => {
  const {
    formError,
    selectedFile,
    preview,
    hotelName,
    address,
    hotelId,
    city,
    province,
    phoneNumber,
    postalCode,
    category,
    loading,
    adminEmail,
    adminPassword,
    secretCode,
    cityData,
    onChangeAdminEmail,
    onChangeAdminPassword,
    onChangeSecretCode,
    onSelectFile,
    onChangeHotelName,
    onChangeAddress,
    onChangeHotelId,
    onChangeCity,
    onChangeProvince,
    onChangePostalCode,
    onChangePhoneNumber,
    onChangeCategory,
    onSelectCity,
    onSubmit,
  } = useAddHotel();
  return (
    <EditHotelCard
      {...{
        formError,
        selectedFile,
        preview,
        hotelName,
        address,
        hotelId,
        postalCode,
        loading,
        city,
        province,
        phoneNumber,
        category,
        adminEmail,
        adminPassword,
        secretCode,
        cityData,
        onChangeAdminEmail,
        onChangeAdminPassword,
        onChangeSecretCode,
        onSelectFile,
        onChangeHotelName,
        onChangeAddress,
        onChangeCity,
        onChangeProvince,
        onChangePhoneNumber,
        onChangeHotelId,
        onChangePostalCode,
        onChangeCategory,
        onSelectCity,
        onSubmit,
      }}
    />
  );
};

export default AddHotel;
