import React from "react";
import { Col, Row } from "react-bootstrap";
import { RiAccountCircleFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";

const CustomerDetailCard = ({ orderData }) => {
  return (
    <div className="white-card px-3 py-3 my-3">
      <h5 className="card-title">Customer Details</h5>
      <div className="horizontal-line" />
      <Row xs="auto">
        <Col>
          <RiAccountCircleFill size={18} />
        </Col>
        <Col className="app-text">{orderData.hostName}</Col>
      </Row>
      <Row xs="auto" className="mt-2">
        <Col>
          <MdEmail size={18} />
        </Col>
        <Col className="app-text">{orderData.hostEmail}</Col>
      </Row>
      <Row xs="auto" className="mt-2">
        <Col>
          <BsTelephoneFill size={14} />
        </Col>
        <Col className="app-text">{orderData.hostPhoneNumber}</Col>
      </Row>
    </div>
  );
};

export default CustomerDetailCard;
