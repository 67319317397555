import { useEffect, useState } from "react";
import { findUsers } from "../../../api";

const useUserList = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const pageSize = 25;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const fetchUsers = () => {
    setLoading(true);
    const body = {
      pageNo: page - 1,
      pageSize,
    };
    findUsers(body)
      .then((res) => {
        setUsers(res.content);
        setPageCount(Math.ceil(res.totalElements / pageSize));
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    fetchUsers();
  }, [page]);

  return {
    users,
    page,
    pageCount,
    loading,
    handlePageChange,
  };
};

export default useUserList;
