import { BiRestaurant } from "react-icons/bi";
import { FaMoneyCheckAlt, FaCity, FaUsers } from "react-icons/fa";

const sidemenuData = [
  {
    id: "hotel",
    label: "Hotels",
    icon: <BiRestaurant size={23} />,
    to: `hotels`,
  },
  {
    id: "accounts",
    label: "Accounts",
    icon: <FaMoneyCheckAlt size={20} />,
    to: `accounts`,
  },
  {
    id: "cities",
    label: "Cities",
    icon: <FaCity size={20} />,
    to: `cities`,
  },
  {
    id: "users",
    label: "Users",
    icon: <FaUsers size={20} />,
    to: `users`,
  },
];

export default sidemenuData;
