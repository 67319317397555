import axios from "axios";
import { API_HEADER, BASE_URL } from "./Config";

const api = axios.create({
  baseURL: BASE_URL,
  timeout: 120000,
  headers: API_HEADER,
});

const getData = async (url) => {
  try {
    const res = await api.get(url);
    if (res) {
      return res.data;
    }
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response);
    } else if (error.message) {
      return Promise.reject(error.message);
    }
  }
};

const postData = async (url, body) => {
  try {
    const res = await api.post(url, body);
    if (res) {
      return res.data;
    }
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response);
    } else if (error.message) {
      return Promise.reject(error.message);
    }
  }
};

// const interceptorUrlBlackList = ["login"];

// Add a request interceptor
// api.interceptors.request.use(
//   function (config) {
//     // console.log("Request Config", config);
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     if (error.request) {
//       alert(error.request.toString());
//     }
//     // console.log("Request Error", error);
//     return Promise.reject(error);
//   }
// );

// Add a response interceptor
// api.interceptors.response.use(
//   function (response) {
//     // console.log("Response config", response);
//     return response;
//   },
//   function (error) {
//     // console.log("Response Error", error?.response);
//     // console.log("Url", error?.response?.config?.url);
//     const hideError = interceptorUrlBlackList.includes(
//       error?.response?.config?.url
//     );
//     if (!hideError) {
//       if (error.response) {
//         alert(error.response.data.message);
//       } else if (error.message) {
//         if (error.message !== "Network Error") {
//           alert(error.message);
//         }
//       } else {
//         alert(error.toString());
//       }
//     }
//     return Promise.reject(error);
//   }
// );

export { getData, postData, api };
