import { getCurrentUser } from "../helpers/Utils";
import urls from "./Urls";
import { getData, postData } from "./Utils";

const userLogin = async (body) => {
  const res = await postData(`${urls.loginUrl}`, body);
  return Promise.resolve(res);
};

const addHotel = async (body) => {
  const user = getCurrentUser();
  const res = await postData(
    `${urls.addHotel}/${user.secretKey}/${user.id}`,
    body
  );
  return Promise.resolve(res);
};

const getHotels = async (body) => {
  const user = getCurrentUser();
  const res = await postData(
    `${urls.getHotels}/${user.secretKey}/${user.id}`,
    body
  );
  return Promise.resolve(res);
};

const getHotelDetailsById = async (id) => {
  const user = getCurrentUser();
  const res = await getData(
    `${urls.getHotelDetailsById}/${user.secretKey}/${user.id}/${id}`
  );
  return Promise.resolve(res);
};

const getStoreAdminDetailsById = async (id) => {
  const user = getCurrentUser();
  const res = await getData(
    `${urls.getStoreAdminDetails}/${user.secretKey}/${user.id}/${id}`
  );
  return Promise.resolve(res);
};

const editHotel = async (body) => {
  const user = getCurrentUser();
  const res = await postData(
    `${urls.editHotel}/${user.secretKey}/${user.id}`,
    body
  );
  return Promise.resolve(res);
};

const addMenu = async (id, body) => {
  const user = getCurrentUser();
  const res = await postData(
    `${urls.addMenu}/${user.secretKey}/${user.id}/${id}`,
    body
  );
  return Promise.resolve(res);
};

const getMenuByHotelId = async (id) => {
  const user = getCurrentUser();
  const res = await getData(
    `${urls.getMenus}/${user.secretKey}/${user.id}/${id}`
  );
  return Promise.resolve(res);
};

const getMenuDetailsById = async (id) => {
  const user = getCurrentUser();
  const res = await getData(
    `${urls.getMenuDetailsById}/${user.secretKey}/${user.id}/${id}`
  );
  return Promise.resolve(res);
};

const editMenu = async (id, body) => {
  const user = getCurrentUser();
  const res = await postData(
    `${urls.editMenu}/${user.secretKey}/${user.id}/${id}`,
    body
  );
  return Promise.resolve(res);
};

const deleteHotel = async (id) => {
  const user = getCurrentUser();
  const res = await getData(
    `${urls.deleteHotel}/${user.secretKey}/${user.id}/${id}`
  );
  return Promise.resolve(res);
};

const deleteMenu = async (id) => {
  const user = getCurrentUser();
  const res = await getData(
    `${urls.deleteMenu}/${user.secretKey}/${user.id}/${id}`
  );
  return Promise.resolve(res);
};

const getOrders = async (body) => {
  const user = getCurrentUser();
  const res = await postData(
    `${urls.getOrders}/${user.secretKey}/${user.id}`,
    body
  );
  return Promise.resolve(res);
};

const getOrderDetailsById = async (id) => {
  const user = getCurrentUser();
  const res = await getData(
    `${urls.getOrderDetails}/${user.secretKey}/${user.id}/${id}`
  );
  return Promise.resolve(res);
};

const addCity = async (body) => {
  const user = getCurrentUser();
  const res = await postData(
    `${urls.addCity}/${user.secretKey}/${user.id}`,
    body
  );
  return Promise.resolve(res);
};

const editCity = async (body) => {
  const user = getCurrentUser();
  const res = await postData(
    `${urls.editCity}/${user.secretKey}/${user.id}`,
    body
  );
  return Promise.resolve(res);
};

const getCities = async (body) => {
  const user = getCurrentUser();
  const res = await postData(
    `${urls.getCities}/${user.secretKey}/${user.id}`,
    body
  );
  return Promise.resolve(res);
};

const findUsers = async (body) => {
  const user = getCurrentUser();
  const res = await postData(
    `${urls.findUsers}/${user.secretKey}/${user.id}`,
    body
  );
  return Promise.resolve(res);
};

const getUserDetailsById = async (id) => {
  const user = getCurrentUser();
  const res = await getData(
    `${urls.getUserDetailsById}/${user.secretKey}/${user.id}/${id}`
  );
  return Promise.resolve(res);
};

export {
  userLogin,
  addHotel,
  getHotels,
  getHotelDetailsById,
  editHotel,
  addMenu,
  getMenuByHotelId,
  getMenuDetailsById,
  editMenu,
  deleteHotel,
  deleteMenu,
  getStoreAdminDetailsById,
  getOrders,
  getOrderDetailsById,
  addCity,
  editCity,
  getCities,
  findUsers,
  getUserDetailsById,
};
