import React, { useState } from "react";
import { Table } from "react-bootstrap";
import EditCityModal from "./editCity";

const CityTable = ({ cityData, refetchData }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [city, setCity] = useState(null);

  const showEditFormModal = () => setShowEditModal(true);
  const hideEditFormModal = () => setShowEditModal(false);

  return (
    <div className="my-3">
      <Table responsive="sm">
        <thead>
          <tr>
            <th>City</th>
            <th></th>
          </tr>
        </thead>
        {cityData.length > 0 && (
          <tbody>
            {cityData.map((item) => (
              <tr className="table-row" key={item.id}>
                <td className="py-3">{item.name}</td>
                <td className="py-3">
                  <button
                    className="btn-blank view-detail-btn"
                    onClick={() => {
                      setCity(item);
                      showEditFormModal();
                    }}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      <EditCityModal
        cityDetail={city}
        visible={showEditModal}
        hideEditFormModal={hideEditFormModal}
        refetchData={refetchData}
      />
    </div>
  );
};

export default CityTable;
