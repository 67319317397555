import React from "react";
import { Col, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { s3BucketConstants } from "../../constants";

const MenuCard = ({ title, image, description, price, onEdit, onDelete }) => {
  // console.log("menu image", image);
  return (
    <Col sm={12} md={6} lg={4} xl={2}>
      <div className="menu-card white-card">
        <div>
          <LazyLoadImage
            className="menu-image"
            width="100%"
            height="100%"
            alt={"MenuImage"}
            effect="opacity"
            placeholderSrc={
              image
                ? `${s3BucketConstants.IMAGE_BASE_URL}/${image}?tr=w-20,h-20,bl-10`
                : "https://via.placeholder.com/150.png?text=No Image"
            }
            src={
              image
                ? `${s3BucketConstants.IMAGE_BASE_URL}/${image}?tr=w-300,h-300`
                : "https://via.placeholder.com/150.png?text=No Image"
            }
          />
        </div>
        <div className="p-3">
          <div className="menu-card-title">{title}</div>
          <div className="menu-description py-1">
            {description || "Description"}
          </div>
          <div className="menu-card-price">$ {price}</div>
        </div>
        <div>
          <Row>
            <Col>
              <button className="btn-blank menu-edit-btn" onClick={onEdit}>
                Edit
              </button>
            </Col>
            <Col>
              <button
                className="btn-blank  menu-delete-btn "
                onClick={onDelete}
              >
                Delete
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
};

export default MenuCard;
