import React from "react";
import { Table } from "react-bootstrap";

const UserTable = ({ users }) => {
  // console.log("Users", users);
  return (
    <div className="my-3">
      <Table responsive="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>City</th>
            <th>Province</th>
            <th>Postal Code</th>
            {/* <th></th> */}
          </tr>
        </thead>
        {users.length > 0 && (
          <tbody>
            {users.map((item) => (
              <tr className="table-row" key={item.id}>
                <td className="py-3">
                  {item.firstName} {item.lastName}
                </td>
                <td className="py-3">{item.email}</td>
                <td className="py-3">{item.phoneNumber}</td>
                <td className="py-3">{item.city}</td>
                <td className="py-3">{item.province}</td>
                <td className="py-3">{item.postalCode}</td>
                {/* <td className="py-3">
                  <button
                    className="btn-blank view-detail-btn"
                    onClick={() => goToDetails(item.id)}
                  >
                    View Details
                  </button>
                </td> */}
              </tr>
            ))}
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default UserTable;
