export const storageKeys = {
  USER_DATA: "create-loyalty-web-user",
};

export const adminRoot = "/app";

export const s3BucketConstants = {
  BUCKET_NAME: "hoppyhour-bucket",
  HOTEL_IMAGE_FOLDER: "heroimages", // It should be same for imagekit and s3
  MENU_IMAGE_FOLDER: "menuimages", // It should be same for imagekit and s3
  IMAGE_BASE_URL: "https://ik.imagekit.io/hoppyhourimageset",
};

export const imagePlaceHolders = {
  HOTEL_IMAGE_PLACEHOLDER_150: "https://via.placeholder.com/150.png?text=No Image",
  MENU_IMAGE_PLACEHOLDER_150: "https://via.placeholder.com/150.png?text=No Image",
  MENU_IMAGE_PLACEHOLDER_75: "https://via.placeholder.com/75.png?text=No Image",
};
