import { useCallback, useEffect, useState } from "react";
import { addCity } from "../../../../api";
import { validateName } from "../../../../validation/validator";

const useAddCity = ({ visible, hideAddFormModal, refetchData }) => {
  const [open, setOpen] = useState(false);
  const [city, setCity] = useState("");
  const [formError, setFormError] = useState(false);

  const onChangeCity = useCallback((e) => {
    setCity(e.target.value);
  }, []);

  useEffect(() => {
    setOpen(visible);
  }, [visible]);

  const handleClose = () => {
    setCity("");
    setFormError(false);
    hideAddFormModal();
    setOpen(false);
  };

  const validator = {
    city: validateName(city),
  };

  const checkFormError = () => {
    setFormError(validator);
  };

  const onAddCity = () => {
    checkFormError();
    const invalidForm = Object.values(validator).some((x) => x !== false);

    const body = {
      latitude: 0,
      longitude: 0,
      name: city,
    };
    // console.log("CITY_BODY", body);
    if (!invalidForm) {
      addCity(body).then((res) => {
        if (res.httpStatus === "OK") {
          handleClose();
          refetchData();
        }
      });
    }
  };

  const handleSubmit = () => {
    onAddCity();
  };

  return {
    city,
    open,
    formError,
    onChangeCity,
    onAddCity,
    handleClose,
    handleSubmit,
  };
};

export default useAddCity;
