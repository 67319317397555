import { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { editMenu, getMenuDetailsById } from "../../../../api";
import S3Bucket from "../../../../appConfig/aws-config";
import { s3BucketConstants } from "../../../../constants";
import useAlert from "../../../../hooks/alert-hook";
import useImageUploader from "../../../../hooks/image-uploader";
import { validateName, validatePrice } from "../../../../validation/validator";

const useEditMenu = () => {
  const { selectedFile, preview, onSelectFile } = useImageUploader();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState(false);

  const { showAppAlert } = useAlert();

  const history = useHistory();
  const location = useLocation();

  const hotelID = location.state.hotelId;
  const menuID = location.state.menuId;
  const storeID = location.state.storeId;

  const onChangeName = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const onChangeDescription = useCallback((e) => {
    setDescription(e.target.value);
  }, []);

  const onChangePrice = useCallback((e) => {
    setPrice(e.target.value);
  }, []);

  useEffect(() => {
    setLoading(true);
    getMenuDetailsById(menuID).then((res) => {
      if (res) {
        if (!res.error) {
          setLoading(false);
          setName(res.name);
          setDescription(res.description);
          setPrice(res.price);
          setImage(res.image);
        } else {
          // console.log("Get menu details error", res.message);
        }
      }
    });
  }, [menuID]);

  const validator = {
    name: validateName(name),
    description: validateName(description),
    price: validatePrice(price),
  };

  const checkFormError = () => {
    setFormError(validator);
  };
  const onEditMenu = (fileName = "") => {
    const body = {
      id: menuID,
      name,
      description,
      price,
      image: fileName || image,
    };
    // console.log("Edit menu body", body);

    editMenu(hotelID, body)
      .then((res) => {
        if (res) {
          setLoading(false);
          showAppAlert("success", "Updated successfully");
          history.push({
            pathname: "hoteldetail",
            state: { hotelId: hotelID },
          });
        }
      })
      .catch((err) => setLoading(false));
  };

  const uploadImage = () => {
    const uniqueId = `${storeID}-${Date.now()}`.replace(" ", "_");

    const fileName = `${
      s3BucketConstants.MENU_IMAGE_FOLDER
    }/${uniqueId}.${selectedFile.name.split(".").pop()}`;

    const params = {
      Body: selectedFile,
      Bucket: s3BucketConstants.BUCKET_NAME,
      Key: fileName,
      ACL: "public-read",
      ContentDisposition: "inline",
      ContentType: selectedFile.type,
    };

    try {
      S3Bucket.putObject(params)
        .on("httpUploadProgress", (evt) => {
          // console.log("Progess", Math.round((evt.loaded / evt.total) * 100));
        })
        .send((err) => {
          if (err) {
            // console.log("Image upload failed", err);
            showAppAlert("error", "Image upload failed");
            setLoading(false);
          } else {
            onEditMenu(fileName);
          }
        });
    } catch (error) {
      setLoading(false);
      // console.log("Upload error", error);
      showAppAlert("error", "Image upload failed");
    }
  };

  const deleteImage = () => {
    try {
      S3Bucket.deleteObject(
        { Bucket: s3BucketConstants.BUCKET_NAME, Key: image },
        (err, data) => {
          if (err) {
            setLoading(false);
            showAppAlert("error", "Image deletion operation failed");
          } else {
            uploadImage();
          }
        }
      );
    } catch (error) {
      setLoading(false);
      showAppAlert("error", "Image deletion operation failed");
    }
  };

  const onSubmit = () => {
    checkFormError();
    const invalidForm = Object.values(validator).some((x) => x !== false);
    if (!invalidForm) {
      setLoading(true);
      if (selectedFile) {
        image ? deleteImage() : uploadImage();
      } else {
        onEditMenu();
      }
    }
  };

  return {
    loading,
    selectedFile,
    preview,
    hotelID,
    name,
    description,
    price,
    image,
    formError,
    onChangeName,
    onChangeDescription,
    onChangePrice,
    onSelectFile,
    onSubmit,
  };
};

export default useEditMenu;
