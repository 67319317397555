import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ErrorHandlerProvider from "./context/errorhandlerContext";
import { AuthRoute, ProtectedRoute } from "./helpers/authHelper";
import LoginScreen from "./views/user/login";
import ViewApp from "./views/app";
import ViewError from "./views/error";
import ViewUnauthorized from "./views/unauthorized";

// const ViewApp = React.lazy(() => import("./views/app"));
// const ViewError = React.lazy(() => import("./views/error"));
// const ViewUnauthorized = React.lazy(() => import("./views/unauthorized"));

const App = () => {
  return (
    <>
      <ErrorHandlerProvider>
        <Router>
          <Switch>
            <ProtectedRoute path="/app" component={ViewApp} />
            <Redirect exact from="/" to="/app" />
            <AuthRoute path="/user/login" component={LoginScreen} />
            <Route
              path="/error"
              exact
              render={(props) => <ViewError {...props} />}
            />
            <Route
              path="/unauthorized"
              exact
              render={(props) => <ViewUnauthorized {...props} />}
            />
            <Redirect to="/error" />
          </Switch>
        </Router>
      </ErrorHandlerProvider>
    </>
  );
};

export default App;
