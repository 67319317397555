import { useEffect, useState } from "react";
import { deleteHotel, getHotels } from "../../../api";
import useAlert from "../../../hooks/alert-hook";

const useHotelList = () => {
  const [hotels, setHotels] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const pageSize = 25;

  const { showAppAlert } = useAlert();

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const fetchHotels = () => {
    setLoading(true);
    const body = {
      pageNo: page - 1,
      pageSize,
    };
    getHotels(body).then((res) => {
      if (res) {
        // console.log("hotel res", res);
        if (!res.error) {
          // console.log("hotels", res.content);
          setPageCount(Math.ceil(res.totalElements / pageSize));
          setHotels(res.content);
          setLoading(false);
        } else {
          // console.log("Get Hotels Error", res.message);
        }
      }
    });
  };

  useEffect(() => {
    fetchHotels();
  }, [page]);

  const onDeleteHotel = (id) => {
    setLoading(true);
    setOpenDialog(false);
    deleteHotel(id).then((res) => {
      if (res) {
        if (res.httpStatus === "OK") {
          setDeleteId("");
          showAppAlert("success", "Deleted successfully");
          fetchHotels();
        } else if (res.error) {
          // console.log("Delete hotel error", res.message);
        } else {
          // console.log("Something went wrong");
        }
      }
    });
  };

  const showConfirmation = (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };

  const onProceed = () => {
    onDeleteHotel(deleteId);
  };

  const onClose = () => setOpenDialog(false);

  return {
    hotels,
    page,
    pageCount,
    loading,
    openDialog,
    onClose,
    onProceed,
    showConfirmation,
    handlePageChange,
  };
};

export default useHotelList;
