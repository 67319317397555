import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getOrderDetailsById } from "../../../../api";

const useOrderDetails = () => {
  const [orderData, setOrderData] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const orderID = location?.state?.orderId;

  // console.log("orderID", orderID);

  const fetchOrderDetails = () => {
    setLoading(true);
    getOrderDetailsById(orderID)
      .then((res) => {
        if (res.order) {
          setOrderData(res.order);
          setOrderItems(res.orderDetails);
          // console.log("Resss", res);
          setLoading(false);
        }
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  return { orderData, orderItems, loading };
};

export default useOrderDetails;
