import React from "react";
import useOrderDetails from "./util";
import Loader from "../../../../components/loader";
import OrderDetailCard from "./orderDetailCard";
import ProductsCard from "./productsCard";
import CustomerDetailCard from "./customerDetailCard";
import ShopDetailCard from "./shopDetailCard";
import PriceDetailCard from "./priceDetails";

const OrderDetalis = () => {
  const { orderData, orderItems, loading } = useOrderDetails();

  // console.log("Order data", orderData);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="page-container">
      <h2 className="page-title">Order Details</h2>
      <OrderDetailCard orderData={orderData} />
      <ProductsCard orderItems={orderItems} orderData={orderData} />
      <CustomerDetailCard orderData={orderData} />
      <ShopDetailCard orderData={orderData} />
      <PriceDetailCard orderData={orderData} />
    </div>
  );
};

export default OrderDetalis;
