import AWS from "aws-sdk";
import { s3BucketConstants } from "../constants";

const bucketName = s3BucketConstants.BUCKET_NAME;
const region = "us-east-1";
const accessKeyId = process.env.REACT_APP_S3_BUCKET_ACCESSKEY_ID;
const secretAccessKey = process.env.REACT_APP_S3_BUCKET_SECRET_ACCESSKEY;

AWS.config.update({
  accessKeyId: accessKeyId,
  secretAccessKey: secretAccessKey,
});

const S3Bucket = new AWS.S3({
  params: { Bucket: bucketName },
  region: region,
});

export default S3Bucket;
